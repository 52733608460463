import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Coupon() {
    const { code } = useParams();
    const navigate = useNavigate();
    const userid=JSON.parse(localStorage.getItem('pubuser'));
    if(localStorage.getItem('pubuser')===null){
            navigate('/login');
        }
    const user=userid;


    const checkCoupon = async () => {
       

        try {
            const response = await fetch('https://api.singhpublication.in/api/order/createorderCoupon', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Specify content type
                },
                body: JSON.stringify({ code,user }) // Convert body to JSON string
            });

            if (response.ok) {
                 toast.info("Added to Orders");
                // console.log('Coupon is valid');
            } else {
                // console.log('Coupon is invalid');
            }
        } catch (error) {
            // console.error('Error checking coupon:', error);
        }
    };

    useEffect(() => {
        checkCoupon();
    }, []); 

    return (
        <>
            <h1>Coupon code is {code}</h1>
            <button onClick={ navigate('/orders')}>Go TO Orders</button>
            {/* <button onClick={checkCoupon}>Apply</button> */}
        </>
    );
}

export default Coupon;
