import React, {useEffect} from 'react'
import "./Homepage.css";

import g1 from ".././src/assets/g1.jpg";
import g2 from ".././src/assets/g2.jpg";
import g3 from ".././src/assets/g3.jpg";
import g4 from ".././src/assets/g4.jpg";
import g5 from ".././src/assets/g5.jpg";
import g6 from ".././src/assets/g6.jpg";


const Imagegallery = () => {
    useEffect(() => {
        const images = document.querySelectorAll('.image');
        const popup = document.querySelector('.popup');
        const closeBtn = document.querySelector('.close-btn');
        const imageName = document.querySelector('.image-name');
        const LargeImage = document.querySelector('.large-image');
        const imageIndex = document.querySelector('.index');
        const leftArrow = document.querySelector('.left-arrow');
        const rightArrow = document.querySelector('.right-arrow');
    
        let index = 0; // will hold the current image index
    
        const updateImage = (i) => {        
          let path =`g${i + 1}.jpg`;
          LargeImage.src = path;
          imageName.innerHTML = path;
          imageIndex.innerHTML = `0${i + 1}`;
          index = i;
        };
    
        images.forEach((item, i) => {
          item.addEventListener('click', () => {
            updateImage(i);
            popup.classList.toggle('active');
          });
        });
    
        closeBtn.addEventListener('click', () => {
          popup.classList.toggle('active');
        });
    
        leftArrow.addEventListener('click', () => {
          if (index > 0) {
            updateImage(index - 1);
          }
        });
    
        rightArrow.addEventListener('click', () => {
          if (index < images.length - 1) {
            updateImage(index + 1);
          }
        });
    
        // Cleanup function to remove event listeners when component unmounts
        return () => {
          images.forEach((item, i) => {
            item.removeEventListener('click', () => {
              updateImage(i);
              popup.classList.toggle('active');
            });
          });
    
          closeBtn.removeEventListener('click', () => {
            popup.classList.toggle('active');
          });
    
          leftArrow.removeEventListener('click', () => {
            if (index > 0) {
              updateImage(index - 1);
            }
          });
    
          rightArrow.removeEventListener('click', () => {
            if (index < images.length - 1) {
              updateImage(index + 1);
            }
          });
        };
      }, []); 
    

  return (
    <>
    <div className="popup"> 

<div className="top-bar">
    <p className="image-name">img.1</p>
    <span className="close-btn"></span>
</div>

        {/* <!-- arrows --> */}
        <button className="arrow-btn left-arrow"><img src="https://th.bing.com/th/id/OIP.RpuO0VQDPivWn8MlB9G8GwHaGW?rs=1&pid=ImgDetMain" alt="left" className="img1"/></button>
        <button className="arrow-btn right-arrow"><img src="https://flyclipart.com/thumb2/right-arrow-angle-of-two-shapes-png-icon-free-download-241720.png" alt="right" className="img1"/></button>
        {/* <!-- image --> */}
        <img src="image/g1.jpg" className="large-image"alt=""/>
        {/* <!-- image index --> */}
        <h1 className="index">01</h1>
    </div>


<div className="gallery">
<div className="gallery-image">
    <img src={g1} className="image" alt=""/>
</div>
<div className="gallery-image">
    <img src= {g2} className="image" alt=""/>
</div>
<div className="gallery-image">
    <img src= {g3} className="image" alt=""/>
</div>
<div className="gallery-image">
    <img src= {g4} className="image" alt=""/>
</div>
<div className="gallery-image">
    <img src= {g5} className="image" alt=""/>
</div>
<div className="gallery-image">
    <img src= {g6} className="image" alt=""/>
</div>
</div> 
    </>
    
  )
}

export default Imagegallery