import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Audiobook = () => {
  const location = useLocation();
  const [base64Audio, setBase64Audio] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await fetch(`https://api.singhpublication.in/api/product/fileAudio?audioId=${location.state.book}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else {
          const data = await response.json();
          // console.log(data); // Log the fetched data
          setBase64Audio(data); // Set the fetched base64 data to state
          setLoading(false); // Set loading to false when data is fetched
        }
      } catch (error) {
        // console.error('Error fetching audio:', error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchAudio();
  }, [location.state.book]); 

  useEffect(() => {
    if (!base64Audio) return;
    audioRef.current.src = `data:audio/mpeg;base64,${base64Audio}`;
    audioRef.current.play(); // Play audio once base64 data is set
  }, [base64Audio]);

  return (
    <>
      {loading && <p>Loading...</p>} {/* Render loading indicator if loading */}
      <audio ref={audioRef} controls />
    </>
  );
};

export default Audiobook;
