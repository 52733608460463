import { initializeApp } from "firebase/app";
// import firebase from 'firebase/compat/firestore/dist/';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCn_Wjx_rD82-EZr7ubJlFzEu1F46BhGxI",
  authDomain: "zulu-proj.firebaseapp.com",
  databaseURL: "https://zulu-proj-default-rtdb.firebaseio.com",
  projectId: "zulu-proj",
  storageBucket: "zulu-proj.appspot.com",
  messagingSenderId: "275511493144",
  appId: "1:275511493144:web:13c8d3fe94afeefe06796d",
  measurementId: "G-F8HZQQ40BS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
