import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { pdfjs } from 'react-pdf';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const MyPdfViewer = () => {
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState('');

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await fetch(`https://api.singhpublication.in/api/product/filePdf?pdfID=${location.state.book}`);
        if (!response.ok) {
          throw new Error('Failed to fetch PDF data');
        }
        const data = await response.json();
        
        setPdfData(data); 

        // console.log(pdfData)
      } catch (error) {
        console.error('Error fetching PDF data:', error);
      }
    };

    fetchPdfData();
  }, [location.state.book]);

  const onPageLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className='bg-gray-500'>
      {pdfData && (
        <Document
          file={`data:application/pdf;base64,${pdfData}`} // Set Base64 data as file
          onLoadSuccess={onPageLoadSuccess}
        >
          <Page pageNumber={1} scale={2} />
        </Document>
      )}
    </div>
  );
}

export default MyPdfViewer;
